<template>
  <div>
    <div>
      <div class="bottom-footer">
        <div class="container">
          <div class="row">
            <p style="padding: 15px">
              Sản phẩm này không phải là thuốc. Không có tác dụng thay thế thuốc
              chữa bệnh.
            </p>
          </div>
        </div>
      </div>
      <div
        class="footer-fixed hidden-sm hidden-md hidden-lg"
        style="z-index: 200"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-4 single-fixed fixed-1">
              <a href="#items-direction">
                <span class="arrow" aria-hidden="true">↑</span>Lên đầu
              </a>
            </div>
            <div class="col-xs-4 single-fixed fixed-2">
              <a href="tel:097136356" style="color: #fff"> 0971.356.356 </a>
            </div>
            <div
              class="col-xs-4 single-fixed fixed-3"
              @click="isShowBuyModal = true"
              style="cursor: pointer"
            >
              <a class="open-buy open-buy-modal">
                <span> Đặt hàng ngay </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-buymodal" v-if="isShowBuyModal">
      <!-- Modal content -->
      <div class="content-buymodal" style="overflow: hidden">
        <a class="close-buymomdal" @click="isShowBuyModal = false">&times;</a>
        <div class="buy-left-img">
          <img src="../../../assets/images/hop6am.png" />
        </div>
        <div class="buy-right-img">
          <p class="buy-lead-buymodal">MẤT NGỦ - SIX.AM LÀ ĐỦ!</p>
          <ul>
            <li>
              Liệu trình dùng thử (10 ngày):
              <span>1 hộp SIX.am có giá 300.000 đồng + phí ship </span>
            </li>
            <li>
              Liệu trình 1 tháng:
              <span>2 hộp SIX.am có giá 600.000 đồng (miễn phí ship)</span>
            </li>
            <li>
              Liệu trình 2 tháng:
              <span>4 hộp SIX.am có giá 1.120.000 đồng (miễn phí ship)</span>
            </li>
          </ul>
          <div class="modal-muangay">
            <form>
              <div class="form-name">
                <span class=""
                  ><input
                    type="text"
                    name="name"
                    value=""
                    size="40"
                    class="input-name"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Họ và tên"
                    v-model="formRegister.FullName"
                /></span>
              </div>
              <div class="form-number">
                <span class=""
                  ><input
                    type="number"
                    name="number"
                    value=""
                    class="input-number"
                    min="99999999"
                    max="9999999999"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Số điện thoại"
                    v-model="formRegister.PhoneNumber"
                /></span>
              </div>
              <div class="">
                <span class="form-course"
                  ><select
                    name="course"
                    class="input-course"
                    aria-required="true"
                    aria-invalid="false"
                    @change="onChange($event)"
                    v-model="selecteded"
                  >
                    <option value="" disabled selected>Chọn liệu trình</option>
                    <option
                      value="Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 300.000 đồng + phí ship"
                    >
                      Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 300.000
                      đồng + phí ship
                    </option>
                    <option
                      value="Liệu trình 1 tháng: 2 hộp SIX.am có giá 600.000 đồng (miễn phí ship)"
                    >
                      Liệu trình 1 tháng: 2 hộp SIX.am có giá 600.000 đồng (miễn
                      phí ship)
                    </option>
                    <option
                      value=" Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.120.000 đồng (miễn phí ship)"
                    >
                      Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.120.000 đồng
                      (miễn phí ship)
                    </option>
                  </select></span
                >
              </div>
              <div class="">
                <span class="form-address">
                  <textarea
                    name="address"
                    cols="40"
                    rows="10"
                    class="input-address"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Địa chỉ nhận hàng"
                    v-model="formRegister.Note"
                  ></textarea>
                </span>
              </div>
              <p
                v-if="isShowWarning"
                class="mt-2"
                style="color: #fff; margin-bottom: 16px"
              >
                {{ contentWarning }}
              </p>
              <p
                v-if="isShowSuccess"
                class="mt-2"
                style="color: #fff; margin-bottom: 16px"
              >
                {{ contentSuccess }}
              </p>
              <div @click="createWebRegister" class="btn-summit">
                <span role="status" aria-hidden="true" v-if="loadding"></span>
                Đặt hàng
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CreateWebRegister } from "@/api/place";
export default {
  data() {
    return {
      isShowBuyModal: false,
      isShowWarning: false,
      contentWarning: "Vui lòng điền đủ thông tin!",
      isShowSuccess: false,
      contentSuccess:
        "Đăng kí thành công, nhân viên CSKH sẽ sớm liên hệ với quý khách!",
      loadding: false,
      selecteded: "",
      disciption: "",
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
        Note: "",
      },
    };
  },
  methods: {
    onChange(event) {
      this.disciption = event.target.value;
    },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.disciption.length == 0 ||
        this.formRegister.Note.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + " (six.am)";
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "SIX.am",
          TypeDoc: "DK",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.disciption,
          LocationRegister: 0,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowBuyModal = false;
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.close-buymomdal {
  color: #fff;
}
a {
  color: #337ab7;
}
</style>
