import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const storeconfig= {
    state:{
        postId: null
    },
    getters:{
    
    },
    mutations:{
        updatePostID (state, payload) {
            state.postId = payload;
          }
    },
    actions:{

    }
}
const store = new Vuex.Store(storeconfig);
export default store