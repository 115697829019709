import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

import home from "../views/Home/index.vue";
import uses from "../views/Uses/index-uses.vue";
import solution from "../views/Solution/index-Solution.vue";
import salepoint from "../views/SalePoint/index-Sale.vue";
import elementpost from "../views/SinglePost/index-SinglePost.vue";
import NotFound from "../views/ErrorPage/NotFound";
export const constantRoutes = [
  {
    path: "/",
    component: home,
  },
  {
    path: "/cong-dung",
    component: uses,
  },
  {
    path: "/blogs",
    component: solution,
  },
  {
    path: "/diem-ban",
    component: salepoint,
  },
  {
    path: "/:id",
    component: elementpost,
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
  },
];
const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    mode: "history",
    routes: constantRoutes,
  });
const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
