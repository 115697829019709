<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-6 footer-logo">
          <p class="ft-sub">Thực phẩm bảo vệ sức khỏe</p>
          <img
            class="hidden-md hidden-sm hidden-lg"
            width="100%"
            src="../../../assets/images/logo-footer.png"
          />
          <img
            class="hidden-xs"
            width="100%"
            src="../../../assets/images/logo.png"
          />
        </div>
        <div class="col-md-6 footer-form">
          <p style="color: #e1cc4f">Đăng ký Chuyên gia tư vấn về mất ngủ</p>
          <form>
            <div class="w-70 contact-left">
              <span
                ><input
                  type="number"
                  v-model="formRegister.PhoneNumber"
                  min="99999999"
                  max="9999999999"
                  placeholder="Số điện thoại"
              /></span>
            </div>
          
           
            <div class="btn-advice-footer" @click="createWebRegister">
              <span role="status" aria-hidden="true"></span>
              Đăng ký tư vấn
            </div>
              <p
              v-if="isShowWarning"
              class="mt-2"
              style="color: #fff; margin-bottom: 16px;margin-top: 16px"
            >
              {{ contentWarning }}
            </p>
             <p
              v-if="isShowSuccess"
              class="mt-2"
              style="color: #fff; margin-bottom: 16px;margin-top: 16px"
            >
              {{ contentSuccess }}
            </p>
          </form>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { CreateWebRegister } from "@/api/place";
export default {
  data() {
    return {
      formRegister: {
        PhoneNumber: "",
      },
      contentSuccess:
        "Đăng kí thành công, nhân viên CSKH sẽ sớm liên hệ với quý khách!",
      loadding: false,
      isShowWarning: false,
      isShowSuccess: false,
    };
  },
  methods: {
    // btClose() {
    //   this.$emit("btCloseContent", false);
    // },
    createWebRegister() {
      if (this.formRegister.PhoneNumber.length == 0) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.formRegister.FullName = "";
      this.formRegister.Note = "";
      this.formRegister.Desctiption = "";
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + " (six.am)"
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "SIX.am",
          TypeDoc: "TV",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.formRegister.Desctiption,
          LocationRegister: 0
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style>
@import "~@/assets/css/lp6am.css";
@import "~@/assets/css/less/baomoi.less";
@import "~@/assets/css/style-default.css";
@import "~@/assets/css/less/diemban.less";
@import "~@/assets/css/less/header.less";
@import "~@/assets/css/less/gift.less";
.btn-advice-footer {
  display: inline-block;
  width: 30%;
  background-color: #cd2653;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  cursor: pointer;
  font-size: 14px;
  padding: 10px !important;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-family: Quicksand Regular;
  line-height: 25px;
}
.btn-advice-footer:hover {
  text-decoration: none;
}
@media (min-width: 765px) and (max-width: 1200px) {
  .btn-advice-footer {
    font-size: 12px !important;
  }
}
@media (max-width: 765px) {
  .btn-advice-footer {
    width: 100%;
    display: inline-block;
    font-size: 18px;
  }
}
</style>