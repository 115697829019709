import request from "./request";
export function GetPlaceByProduct(data) {
  return request({
    url: "Place/GetPlaceByProduct",
    method: "post",
    data: data,
  });
}

export function GetWebPostsLst(data) {
  return request({
    url: "WebContent/GetWebPostsLst",
    method: "post",
    data: data,
  });
}
export function CreateWebRegister(data) {
  return request({
    url: "WebContent/CreateWebRegister",
    method: "post",
    data: data,
  });
}

export function GetWebPostsByID(data) {
  return request({
    url: "WebContent/GetWebPostsByID",
    method: "post",
    data: data,
  });
}
