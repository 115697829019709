<template>
    <div>
          <banneruses/>
          <introduceuses/>
           <footeruses/>
           <advicesixam/>
    </div>

</template>

<script>    
import footeruses from '../Home/components/Footer-Sixam.vue'
import banneruses from '../Uses/components/Banner-Uses.vue'
import introduceuses from '../Uses/components/Introduce-User.vue';
import advicesixam from '../Home/components/Advice-Sixam.vue'

export default {
 
  components: {
    introduceuses,
    banneruses,
    footeruses,
    advicesixam
  },
   mounted(){
      this.$emit("clickButton","congdung")
  }
}
</script>

<style>

</style>