<template>
  <div>
  <div class="w-100 bm-banner">
    <a href="/">
        <img class="bm-banner-img" src="../../../assets/images/banner-baomoi.jpg"/>
    </a>
</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
@import "~@/assets/css/lp6am.css";
</style>