<template>
  <div>
    <a class="to-the-top" id="to-the-top" href="#items-direction">
      <span class="to-the-top-long"><i class="fa-solid fa-arrow-up"></i></span>
      <span class="to-the-top-short"
        ><i class="fa-solid fa-arrow-down"></i>
      </span>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "~@/assets/css/less/footer.less";
@import "~@/assets/css/style-default.css";
@import "~@/assets/css/lp6am.css";
</style>