<template>
  <div>
     <blogelement/>
     
      <footerweb/>  
      <advicesixam/>


  </div>
</template>

<script>
import blogelement from './components/Blog-Elelement.vue'
import footerweb from '../Home/components/Footer-Sixam.vue'
import advicesixam from '../Home/components/Advice-Sixam.vue'
export default {

  components: {
  blogelement,
  advicesixam,
  footerweb,
  },
  mounted(){
      this.$emit("clickButton","blogs")
  }
}
</script>

<style>

</style>