import Vue from 'vue'
import app from './App.vue'
import router from './router'
import store from './store/index'

import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(app),
  store,
}).$mount('#app')
