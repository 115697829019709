<template>
 <div>
   <elementsale/>
   <footerweb/>
   <advicesixam/>
 </div>
</template>

<script>
import elementsale from './components/Element-SalePoint.vue'
import footerweb from '../Home/components/Footer-Sixam.vue'
import advicesixam from '../Home/components/Advice-Sixam.vue'
export default {
  
    components: {
        elementsale,
        footerweb,
        advicesixam,
    },
     mounted(){
      this.$emit("clickButton","diemban")
  }
}
</script>

<style>

</style>