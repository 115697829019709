<template>
  <div>
    <div id="items-direction" style="position: absolute; top: 0"></div>
    <div id="baomoi-content" class="baomoi-content bm-home">
      <img
        style="margin: 0 auto"
        class="bm-banner-img hidden-md hidden-lg"
        src="../../../assets/images/nsut.jpg"
      />
      <img
        style="margin: 0 auto"
        class="bm-banner-img hidden-sm hidden-xs"
        src="../../../assets/images/nsut-d.jpg"
      />

      <div class="container">
        <div class="row">
          <div class="post-blog col-xs-12 col-sm-10">
            <p class="post-heading">{{ postInfo.WebTitle }}</p>
            <!-- <div class="post-view" style="text-align: start; margin-top: -20px; margin-bottom: 15px;">
              <span class="post-view-label" style="margin-right: 5px">
                <i class="fa-solid fa-eye"></i
              ></span>
              <span class="post-view-count">{{ this.countViewPost }}</span>
            </div> -->
            <img :src="postInfo.LinkImage" alt="" class="post-thumb" />
            <p class="blog-category">Chuyên mục: {{ postInfo.Category }}</p>
            <div v-html="postInfo.Description" class="post-content"></div>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>

          <div class="baomoi-content bm-home">
            <div class="container">
              <div class="row">
                <div class="single-post-wrap">
                  <div class="single-content">
                    <div class="content-wrap">
                      <div class="bm-main-content">
                        <div class="bm-feedback">
                          <p class="normal-title">Phản hồi từ khách hàng</p>
                          <div class="single-fb-wrap">
                            <div class="single-cmt-fb">
                              <div class="ava-fb">
                                <img
                                  src="../../../assets/images/ava-fb-1.png"
                                />
                              </div>
                              <div class="cmt-fb">
                                <img
                                  class="hidden-xs"
                                  src="../../../assets/images/cmt-fb-1.jpg"
                                />
                                <img
                                  class="hidden-lg hidden-sm hidden-md"
                                  src="../../../assets/images/cmt-fb-2.jpg"
                                />
                                <div class="cmt-section">
                                  <p class="fb-name">Bui Dung</p>
                                  <p class="fb-txt">
                                    Bị mất ngủ đã lâu, may sao tìm thấy sp sixam
                                    này trên mạng. Mua về dùng đã ngủ lại được.
                                    Xin cám ơn!
                                  </p>
                                </div>
                                <div class="fb-date">
                                  <p>
                                    <span>Thích</span><span>Trả lời</span
                                    ><span class="fb-time">2 giờ trước</span>
                                  </p>
                                </div>
                                <div class="fb-like-count">
                                  <p>23</p>
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="single-fb-wrap">
                              <div class="single-cmt-fb">
                                <div class="ava-fb">
                                  <img
                                    src="../../../assets/images/ava-fb-2.png"
                                  />
                                </div>
                                <div class="cmt-fb">
                                  <img
                                    class="hidden-xs"
                                    src="../../../assets/images/cmt-fb-1.jpg"
                                  />
                                  <img
                                    class="hidden-lg hidden-sm hidden-md"
                                    src="../../../assets/images/cmt-fb-2.jpg"
                                  />
                                  <div class="cmt-section">
                                    <p class="fb-name">Nguyễn Thị Yến</p>
                                    <p class="fb-txt">
                                      Dùng cũng đc 3 hộp rồi. Giấc ngủ có cải
                                      thiện tốt cả nhà ạ! Mong hết liệu trình là
                                      ngủ lại đúng giấc như hồi xưa kkk
                                    </p>
                                  </div>
                                  <div class="fb-date">
                                    <p>
                                      <span>Thích</span><span>Trả lời</span
                                      ><span class="fb-time">3 giờ trước</span>
                                    </p>
                                  </div>
                                  <div class="fb-like-count">
                                    <p>16</p>
                                  </div>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="single-fb-wrap">
                              <div class="single-cmt-fb">
                                <div class="ava-fb">
                                  <img
                                    src="../../../assets/images/ava-fb-3.png"
                                  />
                                </div>
                                <div class="cmt-fb">
                                  <img
                                    class="hidden-xs"
                                    src="../../../assets/images/cmt-fb-1.jpg"
                                  />
                                  <img
                                    class="hidden-lg hidden-sm hidden-md"
                                    src="../../../assets/images/cmt-fb-2.jpg"
                                  />
                                  <div class="cmt-section">
                                    <p class="fb-name">Huỳnh Thanh Xuân</p>
                                    <p class="fb-txt hidden-xs">
                                      Trước cũng ko tin sp quảng cáo. Nhưng mất
                                      ngủ thì không chịu đc. Dùng SIxam này ngủ
                                      được tốt lắm, dậy sảng khoái ko bị met...
                                    </p>
                                    <p
                                      class="fb-txt hidden-sm hidden-md hidden-lg"
                                    >
                                      Trước cũng ko tin sp quảng cáo. Nhưng mất
                                      ngủ thì không chịu đc. Dùng SIxam này ngủ
                                      được tốt lắm...
                                    </p>
                                  </div>
                                  <div class="fb-date">
                                    <p>
                                      <span>Thích</span><span>Trả lời</span
                                      ><span class="fb-time">5 giờ trước</span>
                                    </p>
                                  </div>
                                  <div class="fb-like-count">
                                    <p>29</p>
                                  </div>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="single-fb-wrap">
                              <div class="single-cmt-fb">
                                <div class="ava-fb">
                                  <img
                                    src="../../../assets/images/ava-fb-4.png"
                                  />
                                </div>
                                <div class="cmt-fb">
                                  <img
                                    class="hidden-xs"
                                    src="../../../assets/images/cmt-fb-1.jpg"
                                  />
                                  <img
                                    class="hidden-lg hidden-sm hidden-md"
                                    src="../../../assets/images/cmt-fb-2.jpg"
                                  />
                                  <div class="cmt-section">
                                    <p class="fb-name">Tinh Tran</p>
                                    <p class="fb-txt">
                                      Mới uống 2 ngày nhưng có hiệu quả đấy! Bà
                                      con ai uống đủ liệu trình rồi có thể phản
                                      hồi xem có hết mất ngủ ko???
                                    </p>
                                  </div>
                                  <div class="fb-date">
                                    <p>
                                      <span>Thích</span><span>Trả lời</span
                                      ><span class="fb-time">7 giờ trước</span>
                                    </p>
                                  </div>
                                  <div class="fb-like-count">
                                    <p>14</p>
                                  </div>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="single-fb-wrap">
                              <div class="single-cmt-fb">
                                <div class="ava-fb">
                                  <img
                                    src="../../../assets/images/ava-fb-5.png"
                                  />
                                </div>
                                <div class="cmt-fb">
                                  <img
                                    class="hidden-xs"
                                    src="../../../assets/images/cmt-fb-1.jpg"
                                  />
                                  <img
                                    class="hidden-lg hidden-sm hidden-md"
                                    src="../../../assets/images/cmt-fb-2.jpg"
                                  />
                                  <div class="cmt-section">
                                    <p class="fb-name">Nguyễn Thị Yến</p>
                                    <p class="fb-txt">
                                      Sản phẩm tốt, em mất ngủ sau sinh dùng đủ
                                      loại nhưng SIX.am này là có hiệu quả nhất.
                                      Dậy không bị lơ mơ mệt mỏi đâu
                                    </p>
                                  </div>
                                  <div class="fb-date">
                                    <p>
                                      <span>Thích</span><span>Trả lời</span
                                      ><span class="fb-time">12 giờ trước</span>
                                    </p>
                                  </div>
                                  <div class="fb-like-count">
                                    <p>32</p>
                                  </div>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="single-fb-wrap">
                              <div class="single-cmt-fb">
                                <div class="ava-fb">
                                  <img
                                    src="../../../assets/images/ava-fb-6.png"
                                  />
                                </div>
                                <div class="cmt-fb">
                                  <img
                                    class="hidden-xs"
                                    src="../../../assets/images/cmt-fb-1.jpg"
                                  />
                                  <img
                                    class="hidden-lg hidden-sm hidden-md"
                                    src="../../../assets/images/cmt-fb-2.jpg"
                                  />
                                  <div class="cmt-section">
                                    <p class="fb-name">Khuê Phạm Nhạc</p>
                                    <p class="fb-txt">
                                      T cũng hết mất ngủ rồi. Cám ơn SIX.am! T
                                      sẽ giới thiệu cho bạn be của mình! Ai dang
                                      mát ngủ nên sử dụng thử.
                                    </p>
                                  </div>
                                  <div class="fb-date">
                                    <p>
                                      <span>Thích</span><span>Trả lời</span
                                      ><span class="fb-time">14 giờ trước</span>
                                    </p>
                                  </div>
                                  <div class="fb-like-count">
                                    <p>22</p>
                                  </div>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="bm-form bm-dathang"
                          style="margin-top: 30px"
                        >
                          <p class="form-title">Đặt mua SIX.am</p>
                          <p class="form-info">
                            - Giá bán: 300.000 đồng/hộp 20 ống
                          </p>
                          <p class="form-info">
                            - Sử dụng 1-2 ống/tối trước khi đi ngủ 30-60 phút
                          </p>
                          <p class="form-info">
                            - Rẻ hơn và miễn phí vận chuyển khi mua theo liệu
                            trình:
                          </p>
                          <ul>
                            <li>
                              Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá
                              300.000 đồng+ phí ship
                            </li>
                            <li>
                              Liệu trình 1 tháng: 2 hộp SIX.am có giá 600.000
                              đồng (miễn phí ship)
                            </li>
                            <li>
                              Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.120.000
                              đồng (miễn phí ship)
                            </li>
                          </ul>
                          <div
                            role="form"
                            class="wpcf7"
                            id="wpcf7-f17-o4"
                            lang="en-US"
                            dir="ltr"
                          >
                            <div class="screen-reader-response">
                              <ul></ul>
                            </div>
                            <form
                              action="/#wpcf7-f17-o4"
                              method="post"
                              class="wpcf7-form init"
                              novalidate="novalidate"
                              data-status="init"
                            >
                              <div class="w-100">
                                <span class="wpcf7-form-control-wrap hoten"
                                  ><input
                                    type="text"
                                    name="hoten"
                                    value=""
                                    size="40"
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder="Họ và tên"
                                    v-model="formRegister.FullName"
                                /></span>
                              </div>
                              <div class="w-50 dathang-left">
                                <span class="wpcf7-form-control-wrap sdt"
                                  ><input
                                    type="number"
                                    name="sdt"
                                    value=""
                                    min="99999999"
                                    max="9999999999"
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder="Số điện thoại"
                                    v-model="formRegister.PhoneNumber"
                                /></span>
                              </div>
                              <div class="w-50">
                                <span class="wpcf7-form-control-wrap lieutrinh"
                                  ><select
                                    name="lieutrinh"
                                    aria-required="true"
                                    aria-invalid="false"
                                    @change="onChange($event)"
                                    v-model="selecteded"
                                  >
                                    <option value="" disabled selected>
                                      Chọn liệu trình
                                    </option>
                                    <option
                                      value="Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 300.000 đồng + phí ship"
                                    >
                                      Liệu trình dùng thử (10 ngày): 1 hộp
                                      SIX.am có giá 300.000 đồng + phí ship
                                    </option>
                                    <option
                                      value="Liệu trình 1 tháng: 2 hộp SIX.am có giá 600.000 đồng (miễn phí ship)"
                                    >
                                      Liệu trình 1 tháng: 2 hộp SIX.am có giá
                                      600.000 đồng (miễn phí ship)
                                    </option>
                                    <option
                                      value=" Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.120.000 đồng (miễn phí ship)"
                                    >
                                      Liệu trình 2 tháng: 4 hộp SIX.am có giá
                                      1.120.000 đồng (miễn phí ship)
                                    </option>
                                  </select></span
                                >
                              </div>
                              <div class="w-100">
                                <span class="wpcf7-form-control-wrap diachi">
                                  <textarea
                                    name="diachi"
                                    cols="40"
                                    rows="10"
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder="Địa chỉ nhận hàng"
                                    v-model="formRegister.Note"
                                  ></textarea>
                                </span>
                              </div>
                              <p
                                v-if="isShowWarning"
                                class="mt-2"
                                style="color: #fff; margin-bottom: 16px"
                              >
                                {{ contentWarning }}
                              </p>
                              <p
                                v-if="isShowSuccess"
                                class="mt-2"
                                style="color: #fff; margin-bottom: 16px"
                              >
                                {{ contentSuccess }}
                              </p>
                              <div
                                @click="createWebRegister"
                                class="btn-summit"
                              >
                                <span
                                  role="status"
                                  aria-hidden="true"
                                  v-if="loadding"
                                ></span>
                                Đặt hàng
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="blogs-page col-xs-12">
            <p
              class="blogs-heading col-xs-12"
              style="text-align: left; text-transform: none"
            >
              Tin liên quan
            </p>
            <div
              class="blog-single"
              v-for="(item, index) in tableDateFilter"
              :key="index"
            >
              <img
                :src="item.LinkImage"
                class="blog-thumb"
                @click="btnPushInfor(item)"
                alt=""
              />
              <p class="blog-title" @click="btnPushInfor(item)">
                {{ item.WebTitle }}
              </p>
              <p class="blog-category-nav" @click="btnPushInfor(item)">
                Chuyên mục: {{ item.Category }}
              </p>
              <!-- <p class="blog-des" v-html="item.Description"></p> -->
              <a
                class="blog-readmore"
                style="cursor: pointer"
                @click="btnPushInfor(item)"
                >Xem thêm <i class="fa-solid fa-circle-arrow-right"></i
              ></a>
            </div>

            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Cookies from "js-cookie";
import { CreateWebRegister } from "@/api/place";
import { GetWebPostsLst } from "@/api/place";
import { GetWebPostsByID } from "@/api/place";
export default {
  data() {
    return {
      counter: 1,
      isShowWarning: false,
      isShowSuccess: false,
      contentSuccess:
        "Đăng kí thành công, nhân viên CSKH sẽ sớm liên hệ với quý khách!",
      contentWarning: "Vui lòng điền đủ thông tin!",
      loadding: false,
      selecteded: "",
      disciption: "",
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
        Note: "",
      },
      postInfo: {},
      tableDateFilter: [],
      postCountLst: [],
      countViewPost: 0,
      titleWeb: "",
      rowId: "",
    };
  },
  computed: {
    indexStart() {
      return (this.current - 1) * this.pageSizeInfor;
    },
    indexEnd() {
      return this.indexStart + this.pageSizeInfor;
    },
    paginated() {
      return this.tableData.slice(this.indexStart, this.indexEnd);
    },
    // ...mapState(["postId"])
  },
  methods: {
    // ...mapMutations(["updatePostID"]),
    onChange(event) {
      this.disciption = event.target.value;
    },
    parseURL(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      str = str.replace(
        /!|@|%|\^|\*|\(|\)|\+|\?|\/|,|\.|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
        " "
      );
      str = str.replace(/ /g, "-");
      return str;
    },
    btnPushInfor(item) {
      // this.updatePostID(item.RowID);
      Cookies.set("value", item.RowID);
      this.titleWeb = this.parseURL(item.WebTitle);
      this.$router.push(this.titleWeb);
    },
    parseCurrent(raw) {
      let item = window.location.href.split("https://six-am.vn/");
      if (item.length == 1) {
        item = window.location.href.split("http://localhost:8081/");
      }

      return item[1];
    },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.disciption.length == 0 ||
        this.formRegister.Note.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + " (six.am)";
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "SIX.am",
          TypeDoc: "DK",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.disciption.length,
          LocationRegister: 0,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowBuyModal = false;
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
    getWebPostsLst() {
      GetWebPostsLst({ ProductID: "SIX.am" }).then((res) => {
        this.tableData = res.data.WebPostsLst.filter((p) => p.Status == 1).map(
          (item, index) => {
            return {
              ...item,
              LinkImage:
                "https://icpc1hn.work/DTP/File/DownloadImageWebPosts?UserName=&Token=&RowID=" +
                item.RowID,
              Key: index + 1,
            };
          }
        );
        for (let i = 0; i < this.tableData.length; i++) {
          this.rowIndex =
            this.tableData[Math.floor(Math.random() * this.tableData.length)];
          if (this.rowIndex.RowID != this.currentRowIndex) {
            this.tableDateFilter.push(this.rowIndex);
            this.counter++;
          }
          if (this.counter === 5) break;
        }
        this.getWebPostsByID();
      });
    },
    parseUpper(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getWebPostsByID() {
      this.tableData.forEach((e) => {
        let item = this.parseURL(e.WebTitle);
        if (
          item == this.parseCurrent() ||
          item == this.parseUpper(this.parseCurrent())
        ) {
          GetWebPostsByID({ RowID: e.RowID }).then((res) => {
            var data = res.data;
            this.postInfo = {
              ...data.WebPostsInfo,

              LinkImage:
                "https://icpc1hn.work/DTP/File/DownloadImageWebPosts?UserName=" +
                "&Token=" +
                "&RowID=" +
                data.WebPostsInfo.RowID,
            };
            this.currentRowIndex = data.WebPostsInfo.RowID;
          });
        }
      });
    },
  },
  created() {
    this.getWebPostsLst();
    this.$watch(
      () => this.$route.params,
      () => {
        this.getWebPostsByID();
      }
    );

    //     db.ref("PostSixam").on("value", (snapshot) => {
    //   const data = snapshot.val();
    //   this.postCountLst = data;
    // });
  },
};
</script>

<style lang="css" scoped>
.blog-single {
  width: 50%;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
.blog-title {
  margin-top: 10px;
  font-family: "Mulish Bold";
  font-size: 17px;
  color: black;
}
.blog-category-nav {
  font-family: "Mulish Bold";
  font-size: 17px;
  margin-top: -5px;
}
.blog-category {
  font-family: "Mulish Medium";
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: -5px;
}
.blog-des {
  margin-top: 5px;
  font-family: "Mulish";
  color: black;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-thumb {
  width: 100%;
  height: 350px;
  background-image: url("../../../assets/images/post-thumb.jpg") !important;
}
.mt-2 {
  text-align: center !important;
}
.btn-summit {
  text-align: center;
  font-family: "Quicksand Regular" !important;
  font-size: 17px !important;
  padding: 10px !important;
}
@media (max-width: 990px) {
  .blog-single {
    width: 100%;
  }
}
</style>
<style>
.post-blog img {
  margin-bottom: 20px;
  margin-top: 20px;
}
.post-thumb {
  margin-bottom: 30px;
}
</style>
