<template>
  <div>
    <introduce />
    <footerweb />
    <advicesixam />
  </div>
</template>
<script>
import introduce from "../Home/components/Introduce-Sixam.vue";
import footerweb from "../Home/components/Footer-Sixam.vue";
import advicesixam from "../Home/components/Advice-Sixam.vue";
export default {
  components: {
    introduce,
    footerweb,
    advicesixam,
  },
 
};
</script>

<style>
@import "~@/assets/css/lp6am.css";
@import "~@/assets/css/style-default.css";
@import "~@/assets/bootstrap/css/bootstrap-theme.css";
</style>