<template>
  <div>
    <elementpost />
    <footerweb />
    <advicesixam />
  </div>
</template>

<script>
import elementpost from "./components/elementPost.vue";
import footerweb from "../Home/components/Footer-Sixam.vue";
import advicesixam from "../Home/components/Advice-Sixam.vue";

export default {
  components: {
    elementpost,
    footerweb,
    advicesixam,
  },
};
</script>

<style>
</style>