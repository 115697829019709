<template>

</template>

<script>
export default {

}

</script>

<style>
@import "~@/assets/css/less/homepage.less";
@import "~@/assets/css/less/header.less";
@import "~@/assets/css/less/baomoi.less";

</style>